<!--
 * @Description: 商品库存及价格
 * @Author: 琢磨先生
 * @Date: 2022-06-07 23:44:22
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-07-18 17:49:59
-->

<template>
  <el-form-item label="商品规格" class="is-required">
    <el-alert type="info" :closable="false" title="">
      <el-card
        shadow="never"
        class="sku_box"
        v-for="item in propertyList"
        :key="item.id"
      >
        <div>
          {{ item.name }} <span class="tip">{{ item.tip }}</span>
        </div>
        <el-checkbox-group v-model="item.value_ids">
          <div class="sku_options">
            <div class="item" v-for="x in item.values" :key="x.id">
              <el-checkbox :label="x.id" @change="checkChange(x)">{{
                x.value
              }}</el-checkbox>
              <el-input
                class="input_remark"
                size="small"
                v-model="x.remark"
                placeholder="备注"
              ></el-input>
            </div>
            <div class="item">
              <el-button @click="addCustomValue(item)"
                >+ 自定义{{ item.name }}</el-button
              >
            </div>
          </div>
        </el-checkbox-group>
      </el-card>
    </el-alert>
  </el-form-item>

  <el-form-item
    label="价格及库存"
    class="is-required"
    v-if="tableSkus.length > 0"
  >
    <el-alert type="info" :closable="false" title="">
      <div class="help-block">请如实填写库存信息</div>

      <el-row style="margin: 10px 0px">
        <el-col
          :span="3"
          v-for="(item, i) in columns"
          :key="item.id"
          style="margin-right: 10px"
        >
          <el-select
            v-model="temp.select[i]"
            :placeholder="`${item.name}`"
            clearable
          >
            <el-option
              :label="x.value_name"
              :value="x.value_id"
              v-for="x in item.values"
              :key="x.value_id"
            ></el-option>
          </el-select>
        </el-col>

        <el-col :span="3" style="margin-right: 10px">
          <el-input v-model="temp.price" placeholder="价格(元)" clearable />
        </el-col>
        <el-col :span="3" style="margin-right: 10px">
          <el-input
            v-model="temp.stock_num"
            placeholder="库存 数量(件)"
            clearable
          />
        </el-col>
        <el-col :span="3" style="margin-right: 10px">
          <el-input v-model="temp.cust_code" placeholder="商家编码" clearable />
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="batchModify">批量填充</el-button>
        </el-col>
      </el-row>

      <el-table :data="tableSkus" border>
        <el-table-column
          :label="item.name"
          v-for="item in columns"
          :key="item.id"
          width="180"
        >
          <template #default="scope">
            {{
              scope.row.spec.find((x) => x.property_id == item.id).value_name
            }}
          </template>
        </el-table-column>
        <el-table-column label="价格" width="140">
          <template #header>
            <div class="flex-row item-center">
              <span class="text-danger">*</span>
              价格(元)
            </div>
          </template>
          <template #default="scope">
            <el-input
              v-model="scope.row.decimal_price"
              @change="inputChange"
              placeholder=""
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="库存" width="140">
          <template #header>
            <div class="flex-row item-center">
              <span class="text-danger">*</span>
              库存 数量(件)
            </div>
          </template>
          <template #default="scope">
            <el-input
              v-model="scope.row.stock_num"
              @change="inputChange"
              placeholder=""
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="" width="100">
          <template #header>
            <div class="flex-row item-center">
              <span class="text-danger">*</span>
              预览图
            </div>
          </template>

          <template #default="scope">
            <div class="preview_upload" @click="openImageSpace(scope.row)">
              <el-image
                v-if="scope.row.file_url"
                :src="scope.row.file_url"
                fit="fill"
                style="width: 60px; height: 60px"
              ></el-image>
              <el-icon v-else class="uploader-icon"><Plus /></el-icon>
              <div class="btn_del" v-if="scope.row.file_url">
                <el-icon class="icon-del" @click.stop="deleteImage(scope.row)"
                  ><Close
                /></el-icon>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="上下架" width="80">
          <template #default="scope">
            <el-switch
              v-model="scope.row.is_sale"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="商家编码">
          <template #default="scope">
            <el-input
              v-model="scope.row.cust_code"
              @change="inputChange"
              placeholder=""
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="商品条形码">
          <template #default="scope">
            <el-input
              v-model="scope.row.bar_code"
              @change="inputChange"
              placeholder=""
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
    </el-alert>
  </el-form-item>

  <el-form-item label="一口价" class="is-required">
    <el-col :span="4">
      <el-input v-model="form.price" placeholder="" @change="inputChange">
        <template #append>元</template>
      </el-input>
    </el-col>
    <div class="help-block">未设置商品规格时，必须填写一口价</div>
  </el-form-item>
  <el-form-item label="总库存">
    <el-col :span="4">
      <el-input
        v-model="form.stock_num"
        placeholder=""
        @change="inputChange"
        :disabled="tableSkus.length > 0"
      >
        <template #append>件</template>
      </el-input>
    </el-col>
    <div class="help-block">
      此处是商品所有销售规格总库存数量，若需修改请在销售规格表格内修改对应库存
    </div>
  </el-form-item>
  <custom-image-space
    :type="1"
    :count="1"
    :visible="visibleDialog"
    @change="uploadChange"
    @close="visibleDialog = false"
  ></custom-image-space>
</template>

<script>
import CustomImageSpace from "../../psd/custom_image_space.vue";
export default {
  components: {
    CustomImageSpace,
  },
  data() {
    return {
      //图片选择弹出框
      visibleDialog: false,
      //销售属性列表
      propertyList: [],
      //sku表格数据
      tableSkus: [],
      //表格列
      columns: [],
      temp: {
        select: [],
      },
      form: {},
    };
  },
  emits: ["change"],
  props: ["properties", "goods"],
  watch: {
    properties: {
      handler() {
        if (this.properties) {
          //销售属性
          this.propertyList = this.properties.filter((x) => x.type == 1);
          this.propertyList.forEach((item) => {
            item.value_ids = [];
          });
          this.propertyList.forEach((item) => {
            this.$http
              .get("seller/v1/goods/value/get-custom?property_id=" + item.id)
              .then((res) => {
                if (res.code == 0) {
                  item.values.push(...res.data);
                }
              });
          });
          this.tableSkus = [];
          //原来的sku处理
          if (this.goods.skus && this.goods.skus.length > 0) {
            this.tableSkus.push(...this.goods.skus);
          }
          if (this.tableSkus.length > 0) {
            this.tableSkus.forEach((sku) => {
              sku.spec.forEach((item) => {
                var property = this.propertyList.find(
                  (x) => x.id == item.property_id
                );
                if (property) {
                  if (
                    property.value_ids.findIndex((x) => x == item.value_id) ==
                    -1
                  ) {
                    property.value_ids.push(item.value_id);
                  }
                }
              });
            });
            this.columns = this.tableSkus[0].spec.map((item) => {
              return {
                id: item.property_id,
                name: item.property_name,
              };
            });
          }
        }
      },
      immediate: true,
    },
    goods: {
      handler() {
        if (this.goods) {
          this.form.price = this.goods.price;
          this.form.stock_num = this.goods.stock_num;
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 添加自定义值
     */
    addCustomValue(item) {
      this.$prompt("", `添加自定义${item.name}`, {
        inputPattern: /.+/,
        inputErrorMessage: "请输入",
      })
        .then((val) => {
          console.log(val.value);
          this.$http
            .post("seller/v1/goods/property_value/add", {
              value: val.value,
              property_id: item.id,
            })
            .then((res) => {
              if (res.code == 0) {
                res.data.checked = true;
                item.values.push(res.data);
                this.checkChange();
              }
            });
        })
        .catch(() => {});
    },

    /**
     * 选择更改
     */
    checkChange() {
      this.arr_sku = [];
      var properties = this.propertyList.filter((x) => x.value_ids.length > 0);
      this.columns = properties.map((item) => {
        return {
          id: item.id,
          name: item.name,
          values: item.values
            .filter((o) => item.value_ids.findIndex((x) => x == o.id) > -1)
            .map((x) => {
              return {
                value_id: x.id,
                property_id: item.id,
                value_name: x.value,
                property_name: item.name,
                is_sale: true,
              };
            }),
        };
      });
      this.recursion([], 0, this.columns);

      var old_sku_list = this.tableSkus;
      this.tableSkus = [];
      this.arr_sku.forEach((item) => {
        var obj = { ...item };
        obj.stock_num = "";
        obj.bar_code = "";
        obj.price = "";
        obj.cust_code = "";

        var m = old_sku_list.find((o) => o.sku == obj.sku);
        if (m) {
          obj = { ...m };
        }

        this.tableSkus.push(obj);
      });
      this.inputChange();
    },

    /**
     * 递归处理sku
     */
    recursion(skuarr, i, list) {
      if (list.length > 0) {
        for (let j = 0; j < list[i].values.length; j++) {
          if (i < list.length - 1) {
            skuarr[i] = list[i].values[j];
            this.recursion(skuarr, i + 1, list);
          } else {
            var specList = [...skuarr, list[i].values[j]];
            var sku = specList
              .map((x) => x.property_id + "x" + x.value_id)
              .join("_");
            this.arr_sku.push({
              sku: sku,
              spec: specList,
            });
          }
        }
      }
    },
    /**
     * 批量填充sku值
     */
    batchModify() {
      if (this.temp.select.length > 0) {
        for (var i = 0; i < this.columns.length; i++) {
          var valId = this.temp.select[i];
          if (valId) {
            var proId = this.columns[i].id;
            var list = this.tableSkus.filter(
              (x) =>
                x.spec.findIndex(
                  (o) => o.property_id == proId && o.value_id == valId
                ) > -1
            );
            list.forEach((m) => {
              m.price = this.temp.price;
              m.cust_code = this.temp.cust_code;
              m.stock_num = this.temp.stock_num;
              m.bar_code = this.temp.bar_code;
            });
          }
        }
      } else {
        this.tableSkus.forEach((m) => {
          m.price = this.temp.price;
          m.cust_code = this.temp.cust_code;
          m.stock_num = this.temp.stock_num;
          m.bar_code = this.temp.bar_code;
        });
      }
      this.inputChange();
    },
    /**
     * 弹出图片选择
     */
    openImageSpace(row) {
      this.current_sku = row;
      this.visibleDialog = true;
    },
    /**
     * 图片上传选择后回调
     * @param {*} file
     */
    uploadChange(files) {
      this.visibleDialog = false;
      this.current_sku.file_url = files[0].file_url;
    },
    /**
     * 删除sku的预览图
     */
    deleteImage(sku) {
      this.visibleDialog = false;
      sku.file_url = null;
    },

    /**
     * 数据更改回调
     */
    inputChange() {
      this.$emit("change", this.tableSkus, this.form);
    },
  },
};
</script>

<style  scoped>
.sku_box {
  margin-bottom: 20px;
  border: none;
}
.sku_box:last-child {
  margin-bottom: 0;
}
.tip {
  color: var(--text-tip-color);
  font-size: 12px;
}

.sku_options {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 20px;
}

.sku_options .item {
  display: flex;
  margin-right: 20px;
}
.sku_options .item .input_custom {
  width: 120px;
  margin-left: 10px;
}
.sku_options .item .input_remark {
  width: 80px;
  margin-left: 10px;
}

.batch_form .el-form-item {
  margin-right: 10px !important;
}

/* 预览图 */
.preview_upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  position: relative;
}

.preview_upload:hover {
  border-color: var(--el-color-primary);
}

.preview_upload .el-icon.uploader-icon {
  font-size: 26px;
  color: var(--el-border-color);
  text-align: center;
}

.preview_upload .btn_del {
  position: absolute;
  right: 0px;
  top: 0px;
  background: var(--el-color-danger);
  color: white;
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px;
}
</style>